import React from 'react';
import { Container } from 'react-bootstrap';


export default class Header extends React.Component {
    render() {
        return (
            <>
                <Container>
                    <h1 className='title-home'>aubry lieutier architectes <span>d.p.l.g.</span></h1>                  
                </Container>
            </>
        )
    }
}