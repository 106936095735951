import React from "react";
import Home from "./pages/home";
import { Route, Routes } from "react-router-dom";

import "./App.css";
import Projets from "./pages/projets";
import Concours from "./pages/concours";
import Communications from "./pages/communication";
import Contact from "./pages/contact";
import Mentions from "./pages/mention";

/* import CSS */
import "./css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/projets" element={<Projets />} />
      <Route path="/concours" element={<Concours />} />
      <Route path="/communications" element={<Communications />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/mention" element={<Mentions />} />
    </Routes>
  );
}

export default App;
