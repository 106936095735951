import React from "react";
import { Modal, Carousel } from "react-bootstrap";

export default class ModalSlide extends React.Component {
  render() {
    const { show, onHide, id } = this.props;

    return (
      <Modal show={show} onHide={onHide} animation={true}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <Carousel indicators={false} infinite={false}>
            {id.map(({ img, id, projet }) => (
              <Carousel.Item interval={500000} key={id}>
                <img className="d-block img-modal" src={img} alt={projet} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    );
  }
}
